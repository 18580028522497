<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refRiskyClientsPending'].refresh()"
    >
      <template #table>
        <b-table
          ref="refRiskyClientsPending"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchRiskyClients"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
          :tbody-tr-class="returnClass"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(client)="data">
            <div class="d-flex flex-row">
              <icon-status :icon-status="data.item" />
              <div>
                <router-link
                  class="m-0 text-bold"
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  :to="{
                    name: 'specialist-digital-dashboard',
                    params: { idClient: `${data.item.id_account}` },
                  }"
                  target="_blank"
                >
                  {{ data.item.client }}
                </router-link>
                <br v-if="data.item.user_responsible">
                <span
                  v-if="data.item.user_responsible"
                  class="text-warning"
                >
                  <tabler-icon
                    :icon="
                      data.item.user_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'"
                    size="15"
                  />
                  <strong>CEO's client</strong>
                </span>
                <p class="m-0">
                  {{ data.item.mobile }}
                </p>
                <p class="m-0">
                  {{ data.item.account }}
                  <span class="text-primary" style="font-weight: bold"
                    >({{ data.item.state_eeuu }})
                  </span>
                </p>
              </div>
            </div>
          </template>
          <template v-slot:cell(start_date)="data">
            {{ data.item.start_date | myGlobal }}
          </template>
          <template #cell(monthly_amount)="data">
            $ {{ data.item.monthly_amount }}
          </template>
          <template v-slot:cell(rate)="data">
            <div
              class="d-flex justify-content-center cursor-pointer"
              @click="openCreditExpertsModal(data.item)"
            >
              <feather-icon
                class="text-primary"
                size="22"
                icon="FilePlusIcon"
              />
            </div>
          </template>

          <template v-slot:cell(fee)="data">
            <div>$ {{ data.item.fee }}</div>
          </template>
          <template v-slot:cell(note)="data">
            <div
              class="d-flex justify-content-center cursor-pointer"
              @click="openFirstNoteModal(data.item)"
            >
              <feather-icon class="text-success" size="22" icon="FileIcon" />
            </div>
          </template>

          <template #cell(process_cr)="data">
            <div class="text-center">
              <router-link
                v-if="data.item.stateS == 1"
                :to="{
                  name: 'report-lead-specialistdigital',
                  params: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                    accountId: data.item.id_account,
                    modul: 25,
                  },
                }"
              >
                <feather-icon
                  v-if="data.item.process == null"
                  size="20"
                  :class="isDarkSkin ? 'text-primary' : 'text-dark'"
                  icon="FileTextIcon"
                />
                <feather-icon
                  v-if="data.item.process == 1"
                  size="20"
                  class="text-success"
                  icon="FileTextIcon"
                />
              </router-link>

              <router-link
                v-if="data.item.stateS == 0"
                :to="{
                  name: 'report-lead-specialistdigital',
                  params: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                    accountId: data.item.id_account,
                    modul: 25,
                  },
                }"
              >
                <feather-icon
                  v-if="data.item.process == 1"
                  size="20"
                  class="text-success"
                  icon="FileTextIcon"
                />
              </router-link>
              <router-link
                v-if="data.item.stateSa == 0 && data.item.stateS == 0"
                :to="{
                  name: 'report-lead-specialistdigital',
                  params: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                    accountId: data.item.id_account,
                    modul: 25,
                  },
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.left="
                    'The credit report is not been request'
                  "
                  size="20"
                  :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                  icon="FileTextIcon"
                />
              </router-link>
            </div>
          </template>

          <template v-slot:cell(pdf)="data">
            <div class="d-flex justify-content-center">
              <a
                v-if="data.item.route_pdf != null && data.item.route_pdf != ''"
                :href="data.item.route_file"
                target="_blanck"
              >
                <amg-icon icon="FilePdfIcon" size="18" class="text-success" />
              </a>

              <amg-icon
                v-else
                icon="FilePdfIcon"
                size="18"
                class="text-secondary"
              />
            </div>
          </template>
          <template v-slot:cell(round)="data">
            <div
              v-if="data.item.ncr_id"
              class="text-center cursor-pointer"
              @click="openModalRounds(data.item)"
            >
              <b-badge v-if="data.item.total_rounds_ncr" variant="primary">
                {{ data.item.total_rounds_ncr }}
              </b-badge>
              <b-badge v-else variant="primary"> 0 </b-badge>
            </div>

            <div v-else class="text-center cursor-pointer">
              <b-badge
                title="You have to generate the credit report"
                variant="primary"
              >
                0
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(risk)="data">
            <div
              v-if="data.item.typerisk == null"
              style="width: 120px"
              class="d-flex justify-content-center"
            >
              <feather-icon
                class="text-info cursor pointer"
                v-b-tooltip.hover.left="'Client Risk Clasification'"
                @click="openInsertCommentModal(data.item.id_account)"
                icon="PocketIcon"
                size="20"
              />
            </div>

            <div v-else class="d-flex justify-content-center ml-2">
              <div
                @click="openModalInsertCommentRisky(data.item)"
                v-b-tooltip.hover.top="'View Tracking'"
                class="cursor-pointer"
              >
                <b-badge
                  style="width: 100px"
                  :variant="getRiskyBadgeVariant(data.item.level)"
                  >{{ getRiskyLevelName(data.item.level) }}</b-badge
                >
              </div>

              <div class="d-flex align-items-center">
                <feather-icon
                  icon="Edit2Icon"
                  class="cursor-pointer text-warning"
                  v-b-tooltip.hover.right="'Edit Classification'"
                  style="margin-left: 5px"
                  size="13"
                  @click="
                    openInsertCommentModal(
                      data.item.id_account,
                      data.item.typerisk,
                      data.item.level
                    )
                  "
                />
              </div>
            </div>
          </template>

          <template v-slot:cell(work_plan)="data">
            <div
              variant="outline"
              class="d-flex justify-content-center cursor-pointer"
              @click="openWorkPlanModal(data.item)"
            >
              <feather-icon
                v-b-tooltip.hover.left="
                  data.item.process == 1 &&
                  data.item.stateS == 1 &&
                  data.item.wp_nrc_id
                    ? 'SHOW'
                    : 'ADD'
                "
                size="22"
                :icon="
                  data.item.process == 1 &&
                  data.item.stateS == 1 &&
                  data.item.wp_nrc_id
                    ? 'EyeIcon'
                    : 'PlusCircleIcon'
                "
                :class="
                  data.item.process == 1 &&
                  data.item.stateS == 1 &&
                  data.item.wp_nrc_id
                    ? 'text-success'
                    : 'text-primary'
                "
                style="cursor: pointer"
              />
            </div>
          </template>
          <template #head(risk)>
            <div style="width: 120px !important">RISK</div></template
          >

          <template v-slot:cell(actions)="data">
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                class="mr-2"
                size="sm"
                :disabled="
                  !(
                    data.item.stateS === 1 &&
                    data.item.process === 1 &&
                    data.item.status_id == 5
                  )
                "
                :variant="
                  data.item.stateS === 1 &&
                  data.item.process === 1 &&
                  data.item.status_id == 5
                    ? 'success'
                    : 'secondary'
                "
                @click="done(data.item)"
              >
                Process
              </b-button>

              <b-button
                size="sm"
                :disabled="
                  !(
                    data.item.stateS === 1 &&
                    data.item.process === 1 &&
                    data.item.typerisk
                  )
                "
                :variant="
                  data.item.stateS === 1 &&
                  data.item.process === 1 &&
                  data.item.typerisk
                    ? 'success'
                    : 'secondary'
                "
                @click="insertTrackingClientDigital(data.item)"
              >
                Done
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-insert-comments-risky
      v-if="modalInsertCommentsRiskyOn"
      :data="comments"
      @close="closeModalInsertCommentRisky"
    />
    <modal-insert-risky
      v-if="modalInsertComment"
      :type="typeRisk"
      :client_id="client_id"
      :level="levelRiskyClient"
      @refresh="refresh"
      @close="closeInsertCommentModal"
    />
    <credit-experts-modal
      v-if="modalCreditExperts"
      :sales-client="salesClient"
      :type-modal="2"
      @closeModal="closeCreditExpertsModal"
    />
    <modal-add-work-plan
      v-if="workPlanController"
      :id-ncr="ncrId"
      :id-work-plan="workPlanId"
      :client-name="workPlanData.client"
      :client_id="workPlanData.id_account"
      :account="workPlanData.account"
      @reload="refresh"
      @close="closeWorkPlanModal"
    />

    <add-round-modal
      v-if="addRoundModal"
      :data-round="itemClient"
      :validate-modal="0"
      @refresh="refresh"
      @close="closeModalRounds"
    />
    <modal-done
      v-if="modalDoneState"
      :data-client="dataDone"
      @updateTable="refresh"
      @hideModal="modalDoneState = false"
    />
    <modal-notas-credit
      v-if="creditExpertsDigitalController"
      :note-info="noteInfo"
      :from-dashboard="true"
      @hide="creditExpertsDigitalController = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RiskyService from "@/views/management/views/risky-clients/risky.service";
import ModalInsertCommentsRisky from "@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue";
import filterData from "@/views/specialist-digital/views/clients/components/filters.data";
import ModalInsertRisky from "@/views/specialist-digital/views/clients/components/ModalInsertRisky.vue";
import CrmServices from "@/views/crm/services/crm.service";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";

import ProcessCrServices from "@/views/specialist-digital/views/process-cr/services/process-cr.services";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import ModalDone from "@/views/specialist-digital/views/process-cr/view/components/modals/ModalDone.vue";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";

import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";

export default {
  components: {
    ModalInsertCommentsRisky,
    ModalInsertRisky,
    CreditExpertsModal,
    ModalAddWorkPlan,
    ListAllNotesModal,
    AddRoundModal,
    ModalDone,
    IconStatus,
    ModalNotasCredit,
  },

  data() {
    return {
      // moment date
      levelRiskyClient: null,
      dataDone: {},
      modalDoneState: false,
      isAllNotesActive: false,
      salesClient: {},
      modalCreditExperts: false,
      sellers: [],
      typeRisk: "",
      riskCLient: null,
      client_id: "",
      modalInsertComment: false,
      filters: filterData,
      comments: "",
      modalInsertCommentsRiskyOn: false,
      status: null,
      seller: null,
      program: null,
      statusip: null,
      sourcesname_id: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of clients...",
        model: "",
      },
      fields: [
        {
          key: "client",
          label: "client",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "monthly_amount",
          label: "Monthly Payment",
        },
        {
          key: "fee",
          label: "fee",
          thStyle: "text-align: center",
        },
        {
          key: "note",
          label: "first note",
          thStyle: "text-align: center",
        },

        {
          key: "risk",
          label: "risk",
          thStyle: "text-align: center; width: 120px",
        },
        {
          key: "pdf",
          label: "Pdf",
          thStyle: "text-align: center",
        },
        {
          key: "process_cr",
          label: "Process Cr",
          thStyle: "text-align: center",
        },
        {
          key: "round",
          label: "Rounds",
          thStyle: "text-align: center",
        },
        {
          key: "work_plan",
          label: "Work Plan",
          thStyle: "text-align: center",
        },
        {
          key: "Actions",
          label: "actions",
          thStyle: "text-align: center",
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      mood: "",
      workPlanData: {},
      notesData: {},
      workPlanController: false,
      ncrId: null,
      workPlanId: null,
      addRoundModal: false,
      itemClient: {},

      creditExpertsDigitalController: false,
      noteInfo: {
        nameProgram: "",
        created: "",
        nameClient: "",
        type: 1,
        editModal: false,
        statusSale: 4,
        notSeller: false,
        saleId: "",
        sourcesName: "",
        pack: "",
      },
    };
  },

  computed: {
    ...mapState({
      captured: (state) => state["crm-store"].capturedCrm,
      sources: (state) => state["crm-store"].sources,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    returnClass(item) {
      const first = this.isDarkSkin
        ? "caro-table-light3 "
        : "caro-table-dark3 ";

      const second = this.isDarkSkin
        ? "caro-table-light4 "
        : "caro-table-dark4 ";

      if (item !== null) {
        if (item.valid_24 === "24" && item.valid_48 === "") {
          return `${second}font-size-medium-4 border-white cursor-pointer`;
        }
        if (item.valid_48 === "48" && item.valid_24 === "24") {
          return `${first}border-white cursor-pointer`;
        }
      }
    },

    done(data) {
      this.modalDoneState = true;
      this.dataDone = {
        lead_name: data.client,
        ncr_id: data.ncr_id,
        account: data.account,
      };
    },
    closeModalRounds() {
      this.addRoundModal = false;
    },

    openModalRounds(data) {
      this.itemClient = {
        id: data.ncr_id,
        account: data.account,
        idAccount: data.id_account,
        clientName: data.client,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          origin_country: data.origin_country,
        },
      };
      this.addRoundModal = true;
    },
    closeModalAllNotes() {
      this.isAllNotesActive = false;
    },
    openFirstNoteModal(data) {
      this.noteInfo.nameProgram = data.program;
      this.noteInfo.created = data.creates;
      this.noteInfo.nameClient = data.client;
      this.noteInfo.saleId = data.id;
      this.noteInfo.sourcesName = data.sourcesname;
      this.noteInfo.pack = data.pack;
      this.creditExpertsDigitalController = true;
    },
    closeWorkPlanModal() {
      this.idWorkPlan = "";
      this.workPlanController = false;
    },

    openWorkPlanModal(data) {
      this.workPlanData = data;
      this.ncrId = data.ncr_id;
      this.workPlanId = data.work_plan_id;
      this.workPlanController = true;
    },

    openCreditExpertsModal(data) {
      this.salesClient = data;
      this.modalCreditExperts = true;
    },
    closeCreditExpertsModal() {
      this.modalCreditExperts = false;
    },

    async getSellers() {
      const tempSellers = await CrmServices.getSellersCrm(2, {
        roles: "[]",
        type: "0",
      });

      const formatedSellers = tempSellers.map((seller) => ({
        id: seller.id,
        label: seller.user_name,
      }));

      this.sellers = [{ id: 0, label: "All" }];
      this.sellers.push(...formatedSellers);

      this.filters[3].options = this.sellers;
      this.filters[3].model = null;
    },
    refresh() {
      this.$refs.refRiskyClientsPending.refresh();
    },
    getRiskyBadgeVariant(level) {
      switch (level) {
        case 1:
          return "info";

        case 2:
          return "warning";

        case 3:
          return "danger";

        default:
          return "success";
      }
    },

    getRiskyLevelName(level) {
      switch (level) {
        case 1:
          return "RISK - LOW";

        case 2:
          return "RISK - MEDIUM";

        case 3:
          return "RISK - HIGH";

        default:
          return "NO RISK";
      }
    },
    openInsertCommentModal(data, type, level) {
      this.levelRiskyClient = level;
      this.modalInsertComment = true;
      this.client_id = data;
      this.typeRisk = type;
    },
    closeInsertCommentModal() {
      this.modalInsertComment = false;
    },
    openModalInsertCommentRisky(data) {
      this.modalInsertCommentsRiskyOn = true;
      this.comments = data;
    },
    closeModalInsertCommentRisky() {
      this.modalInsertCommentsRiskyOn = false;
    },

    // eslint-disable-next-line consistent-return
    async insertTrackingClientDigital(info) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            client_id: info.id_account,
            user_id: this.currentUser.user_id,
          };

          // eslint-disable-next-line no-shadow
          const data = await ProcessCrServices.insertTrackingDigitalClients(
            params
          );
          if (data.status === 200) {
            this.showSuccessSwal();
            this.refresh();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async searchRiskyClients(ctx) {
      const params = {
        per_page: ctx.perPage,
        orderby: 2,
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? "asc" : "desc",
        text: this.filterPrincipal.model,
        from: this.filters[0].model,
        to: this.filters[1].model,
        type_risky: null,
        new: null,
      };

      const data = await RiskyService.getDigitalClients(params);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
  },
};
</script>

<style>
.caro-table-light4 {
  background-color: rgba(205, 140, 20, 0.5) !important;
}

.caro-table-dark4 {
  background-color: rgb(255, 247, 232) !important;
}

.caro-table-light3 {
  background-color: rgba(233, 31, 56, 0.5) !important;
}

.caro-table-dark3 {
  background-color: rgb(255, 231, 235) !important;
}
</style>

<style scoped>
.bigger {
  width: 1.35em !important;
  height: 1.35em !important;
}
.pointer {
  cursor: pointer;
}
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
