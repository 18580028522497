<template>
  <div>
    <b-modal
      ref="modal-done"
      modal
      centered
      size="lg"
      header-class="p-0  border-bottom-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="Process"
      title-class="h2 text-white"
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalHeader
          :data-client="dataClient"
          :title="'PROCESS CR'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-row>
          <b-col cols="2" class="ml-1 bg-primary rounded-top paddingTop">
            <h6 class="text-white text-center">OBSERVATION</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-textarea
              id="textarea"
              v-model="obs"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal"> Cancel </b-button>
        <b-button variant="primary" @click="onSubmit"> Save </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { ValidationObserver } from "vee-validate";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalHeader from "./ModalHeader.vue";

export default {
  components: {
    ModalHeader,
    ButtonSave,
    ButtonCancel,
    ValidationObserver,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      obs: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modal-done");
  },
  methods: {
    async onSubmit() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        try {
          const params = {
            id: this.dataClient.ncr_id,
            type: 6,
            idsession: this.currentUser.user_id,
            statustk: 8,
            obs: this.obs,
            typecor: 0,
            elent: "",
            subject: "",
          };
          await ClientsOptionsServices.changeStatusNcr(params);
          this.$emit("updateTable");
          this.hideModal();
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "The satisfying action."
          );
        } catch (error) {
          console.log(error);
        }
      }
    },
    hideModal() {
      this.$refs['modal-done'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped>
.paddingTop {
  padding-top: 12px;
}
</style>
